// FIXME: this file is getting out of hand. should split to smaller components.
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { Slider } from "@/components/ui/slider";
import { LumiDB, SourceFileMetadata } from "@lumidb/lumidb";
import {
    AlignVerticalSpaceAround,
    CalendarClock,
    Camera,
    ChevronDown,
    ChevronUp,
    Circle,
    Database,
    Diamond,
    Dot,
    Download,
    FileBox,
    Filter,
    ListRestart,
    LoaderCircle,
    Lock,
    LogOut,
    LucideIcon,
    Map as MapIcon,
    Minus,
    Paintbrush,
    Play,
    Plus,
    Rotate3D,
    Share2,
    SlidersHorizontal,
    Square,
    SwitchCamera,
} from "lucide-react";
import { Polygon } from "ol/geom";
import "ol/ol.css";
import { useEffect, useMemo, useRef, useState } from "react";
import lumidbLogo from "../assets/lumidb_logo_black.svg";
import { ApiKeyInput } from "./components/ApiKeyInputDialog";
import { SelectTableDialog } from "./components/SelectTableDialog";
import { BASEMAP_SOURCE, LumiMap, SelectedFiles } from "./map";
import { addZRangeToQueryShape, DEFAULT_POINT_MATERIAL, PointChunk, QueryShape } from "./point-chunk";
import { COLOR_MODE, ColorMode, CustomPointMaterial, getColorModeById } from "./point-material";
import "./style.css";
import {
    djb2Hash,
    getClassificationColor,
    getDate,
    getRampedColor,
    humanArea,
    humanDuration,
    humanSize,
} from "./utils";
import { FetchInfo, Viewer } from "./viewer";

const lumidb = new LumiDB("", null);

const lasClasses: Record<number, string> = {
    0: "Unassigned",
    1: "Unclassified",
    2: "Ground",
    3: "Low Vegetation",
    4: "Medium Vegetation",
    5: "High Vegetation",
    6: "Building",
    7: "Noise",
    8: "Keypoint",
    9: "Water",
    10: "Rail",
    11: "Road Surface",
    12: "Overlap",
    13: "Wire",
    14: "Wire Connector",
    15: "Transmission Tower",
    16: "Wire Insulator",
    17: "Bridge Deck",
    18: "High Noise",
};

function Info(props: { heading: string; children: React.ReactNode }) {
    return (
        <div className="mt-1 flex gap-2 text-xs">
            <div className="w-14 text-right font-bold text-gray-800">{props.heading}: </div>
            <div>{props.children}</div>
        </div>
    );
}

const MAX_DENSITY = 50;

const VIEWER_STATE_VERSION = 9;

const defaultViewerState = {
    baseMap: "osm" as keyof typeof BASEMAP_SOURCE,
    colorMode: "rgb" as ColorMode,
    maxPoints: 2_000_000,
    maxDensity: null as null | number,
    version: VIEWER_STATE_VERSION,
    queryShape: null as null | QueryShape,
    classificationMask: 0xffff_ffff,
    camera: null as { position: number[]; rotation: number[]; autoRotate: boolean; mode: "fps" | "map" } | null,
    toggled: {
        date: false,
        pointLimit: false,
        class: false,
    },
};

type ViewerState = typeof defaultViewerState;

let saveStateHandle = 0;
function saveViewerState(change: (state: ViewerState) => void) {
    change(state);

    // round camera coords
    if (state.camera) {
        state.camera.position = state.camera.position.map((n) => Number(n.toFixed(0)));
    }

    const asString = JSON.stringify(state);
    localStorage.setItem("state", asString);

    if (saveStateHandle) {
        clearTimeout(saveStateHandle);
        saveStateHandle = 0;
    }
    saveStateHandle = setTimeout(() => {
        const pars = new URLSearchParams(window.location.search);
        pars.set("state", btoa(asString));
        window.history.replaceState({}, "", "?" + pars.toString());
    }, 200);
}

function loadViewerState(): ViewerState | null {
    const q = new URLSearchParams(window.location.search);
    let s = "";
    if (q.get("state")) {
        s = atob(q.get("state") ?? "");
    } else {
        s = localStorage.getItem("state") ?? "";
        if (s) {
            console.log("load viewer state from localStorage");
        }
    }

    if (!s) {
        console.log("no viewer state stored");
        return null;
    }

    try {
        const newState: ViewerState = JSON.parse(s ?? "");
        if (newState.version === VIEWER_STATE_VERSION) {
            console.log("state", newState);
            return newState;
        } else {
            console.error("unsupported viewer state version", newState);
            resetViewerState();
            return null;
        }
    } catch (e) {
        console.error("failed to parse viewer state", e);
    }
    return null;
}

function resetViewerState() {
    localStorage.removeItem("state");
    // drop query param from URL and reload
    window.history.replaceState({}, "", window.location.origin);
    window.location.reload();
}

const state = loadViewerState() ?? defaultViewerState;

function Collapsible(props: {
    toggled: boolean;
    text: string;
    icon: LucideIcon;
    children: React.ReactNode;
    extra?: React.ReactNode;
    isWarning?: () => boolean;
    buttonClicked: () => void;
}) {
    const button = (
        <Button
            size="sm"
            variant={props.toggled ? "default" : props.isWarning && props.isWarning() ? "destructive" : "outline"}
            onClick={props.buttonClicked}
            className="self-start"
        >
            <props.icon className="h-4" /> {props.text}
        </Button>
    );
    if (!props.toggled) {
        return button;
    }

    return (
        <div className="pointer-events-auto w-fit rounded-md bg-white/40 p-2 text-sm backdrop-blur-sm">
            <div className="mb-2 flex items-center gap-4">
                {button}
                {props.extra}
            </div>
            {props.children}
        </div>
    );
}

const viewer = new Viewer();
const map = new LumiMap();

function ViewerContainer() {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current) return;
        const val = containerRef.current;
        val.appendChild(viewer.renderer.domElement);
        return () => {
            val.removeChild(viewer.renderer.domElement);
        };
    }, []);

    return (
        <div
            id="viewer-container"
            ref={containerRef}
            className="h-full w-full"
            style={{ background: "radial-gradient(rgb(255, 255, 255), rgba(52, 45, 134, 0.15))" }}
        ></div>
    );
}

function getCurrentTable() {
    const pars = new URLSearchParams(window.location.search);
    const t = pars.get("table");
    if (t) {
        return t;
    } else {
        return null;
    }
}

async function downloadSourceFile(tableName: string, sourceFileId: number) {
    const response = await lumidb.getDownloadUrl(tableName, sourceFileId);
    const presignlUrl = response.url;
    const link = document.createElement("a");
    link.href = presignlUrl;
    link.target = "_blank"; // Open in a new tab if necessary
    link.download = ""; // Let the server determine the filename
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Cleanup
}

function FileInfoRow(props: { info: SourceFileMetadata; idx: number; viewer: Viewer | null }) {
    const colorText = "rgb(" + getRampedColor(props.idx).join(", ") + ")";

    return (
        <div
            className="items-top my-1 flex cursor-pointer gap-1"
            key={props.idx}
            onClick={() => {
                const table = getCurrentTable();
                if (table) {
                    downloadSourceFile(table, props.idx);
                }
            }}
        >
            <div style={{ backgroundColor: colorText }} className="h-5 w-5 rounded-sm"></div>
            <div className="font-bold text-gray-700">{props.info.filename}</div>
            <div>{humanSize(props.info.file_size)}</div>
        </div>
    );
}

function FilesByLicense(props: {
    responseInfo: FetchInfo | null;
    fileMetadata: Map<number, SourceFileMetadata>;
    viewer: Viewer | null;
}) {
    const noLicense: [number, SourceFileMetadata][] = [];
    const byLicense: Record<string, [number, SourceFileMetadata][]> = {};

    for (const idx of props.responseInfo?.seenIndices ?? []) {
        const info = props.fileMetadata.get(idx);
        if (!info) {
            continue;
        }

        const license = info.user_data.license;
        if (typeof license === "string") {
            if (byLicense[license]) {
                byLicense[license].push([idx, info]);
            } else {
                byLicense[license] = [[idx, info]];
            }
        } else {
            noLicense.push([idx, info]);
        }
    }

    return (
        <div className="divide-y">
            {noLicense.map(([idx, f]) => (
                <FileInfoRow key={idx} info={f} idx={idx} viewer={props.viewer} />
            ))}

            {Object.entries(byLicense).map(([license, files]) => (
                <div key={license}>
                    <div className="my-1 text-xs font-bold text-gray-800">{license}:</div>
                    {files.map(([idx, f]) => (
                        <FileInfoRow key={idx} info={f} idx={idx} viewer={props.viewer} />
                    ))}
                </div>
            ))}
        </div>
    );
}

function getQueryShapeArea(shape: QueryShape) {
    if ("polygon" in shape) {
        return new Polygon(shape.polygon).getArea();
    } else {
        return (shape.aabb[2] - shape.aabb[0]) * (shape.aabb[3] - shape.aabb[1]);
    }
}

export function App() {
    const mapElement = useRef(null as null | HTMLDivElement);

    const initialized = useRef(false);

    const [apiKey, setApiKey] = useState(localStorage.getItem("apikey") ?? "");
    const [tableDialogOpen, setTableDialogOpen] = useState(false);

    const [tableName, setTableName] = useState(getCurrentTable());

    const tableNameRef = useRef(tableName);

    const [modifyPointLimit, setModifyPointLimit] = useState(state.toggled.pointLimit);
    const [pointLimit, setPointLimit] = useState(state.maxPoints);
    const [densityLimit, setDensityLimit] = useState(state.maxDensity ?? defaultViewerState.maxDensity);
    const [responseInfo, setResponseInfo] = useState(null as null | FetchInfo);

    const [selectedFiles, setSelectedFiles] = useState([] as SelectedFiles);

    const [activeDrawing, setActiveDrawing] = useState(null as null | "box" | "polygon");

    const [filterClassification, setFilterClassification] = useState(state.toggled.class);
    const [classificationMask, setClassificationMask] = useState(state.classificationMask);

    const [colorMode, setColorMode] = useState(state.colorMode);

    const [filterTimeRange, setFilterTimeRange] = useState(state.toggled.date);

    const [zRangeEnabled, setZRangeEnabled] = useState(false);
    const [zRangeRenderOnly, setZRangeRenderOnly] = useState(true);
    const [zRange, setZRange] = useState([-Infinity, Infinity] as number[]);
    const [zRangeSelection, setZRangeSelection] = useState([-Infinity, Infinity] as [number, number]);

    const zRangeEnabledRef = useRef(zRangeEnabled);
    const zRangeRenderOnlyRef = useRef(zRangeRenderOnly);
    const zRangeRef = useRef(zRange);
    const zRangeSelectionRef = useRef(zRangeSelection);

    const [loadCount, setLoadCount] = useState(0);

    const [currentTableMetadata, setCurrentTableMetadata] = useState({ files: new Map<number, SourceFileMetadata>() });
    const [uiLayout, setUiLayout] = useState("horizontal" as "horizontal" | "vertical");

    const [metadataLoaded, setMetadataLoaded] = useState<string | null>(null);

    const [shareLinkText, setShareLinkText] = useState("Share");
    const [filesUsedCollapsed, setFilesUsedCollapsed] = useState(true);

    const [cameraControlMode, setCameraControlMode] = useState(state.camera?.mode ?? "map");

    const tableCRSRef = useRef("EPSG:3857");

    const selectableDates = useMemo(() => {
        return [...new Set(Array.from(currentTableMetadata.files.values()).map((f) => getDate(f)))].sort();
    }, [currentTableMetadata]);

    const [lockedDates, setLockedDates] = useState(new Set<string>());

    const [selectedTimeRange, setSelectedTimeRange] = useState(null as null | [number, number]);

    function updatePoints(why: string) {
        if (tableNameRef.current === null || state.queryShape === null) {
            return;
        }

        return viewer.fetchPoints({
            lumidb: lumidb,
            tableName: tableNameRef.current,
            sourceFileFilter: sourceFileFilterRef.current,
            classificationMask: classMaskRef.current,
            maxPoints: state.maxPoints,
            maxDensity: state.maxDensity,
            queryShape: addZRangeToQueryShape(
                state.queryShape,
                zRangeEnabledRef.current && !zRangeRenderOnlyRef.current ? zRangeSelectionRef.current : null,
            ),
            why: why,
        });
    }

    function onShareLink() {
        const queryParams = new URLSearchParams();
        if (tableName) {
            queryParams.set("table", tableName);
        }
        queryParams.set("state", btoa(JSON.stringify(state)));
        const url = window.location.origin + "?" + queryParams;
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setShareLinkText(() => "Copied!");
                setTimeout(() => {
                    setShareLinkText("Share");
                }, 1000);
                console.log("URL copied to clipboard!", url);
            })
            .catch((err) => {
                console.error("Error copying to clipboard: ", err);
            });
    }

    const sourceFileFilter = useMemo(() => {
        if (currentTableMetadata.files.size > 0) {
            const selectedDates = selectableDates.filter(
                (_, i) => selectedTimeRange === null || (i >= selectedTimeRange[0] && i < selectedTimeRange[1]),
            );

            for (const d of lockedDates) {
                if (!selectedDates.includes(d)) {
                    selectedDates.push(d);
                }
            }

            return Array.from(currentTableMetadata.files.entries())
                .filter(([_, f]) => selectedDates.includes(getDate(f)))
                .map(([idx, _]) => idx);
        } else {
            return null;
        }
    }, [currentTableMetadata.files, selectedTimeRange, selectableDates, lockedDates]);

    const sourceFileFilterRef = useRef(sourceFileFilter);
    useEffect(() => {
        sourceFileFilterRef.current = sourceFileFilter;
    }, [sourceFileFilter]);

    const classMaskRef = useRef(classificationMask);

    // run then initialization once after first render
    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        const viewerContainer = document.getElementById("viewer");
        if (!viewerContainer) {
            throw new Error("#viewer not found");
        }

        function resizeUI() {
            const root = document.getElementById("root");
            if (!root) {
                throw new Error("#root element found");
            }

            if (root.clientHeight > root.clientWidth) {
                setUiLayout("vertical");
            } else {
                setUiLayout("horizontal");
            }

            if (viewerContainer) {
                viewer.setSizeDebounced(viewerContainer.clientWidth, viewerContainer.clientHeight);
            }
        }

        resizeUI();

        window.addEventListener("resize", () => {
            resizeUI();
        });

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === viewerContainer) {
                    resizeUI();
                }
            }
        });

        resizeObserver.observe(viewerContainer);

        viewer.requestRender();

        viewer.addEventListener("fetchCompleted", (info) => {
            const extra = zRangeRenderOnlyRef.current ? 0 : 10;
            setZRange([info.bounds.min.z - extra, info.bounds.max.z + extra]);
            setZRangeSelection([
                Math.max(info.bounds.min.z, zRangeSelectionRef.current[0]),
                Math.min(info.bounds.max.z, zRangeSelectionRef.current[1]),
            ]);
            setResponseInfo(info);
            map.updateCurrentSelection(info.queryShape);
            saveViewerState((state) => (state.queryShape = info.queryShape));
        });

        if (state.camera) {
            console.log("restore camera", state.camera);
            try {
                viewer.restoreCamera(
                    state.camera.position,
                    state.camera.rotation,
                    state.camera.autoRotate,
                    state.camera.mode,
                );
            } catch (e) {
                console.error("Failed to restore camera", e);
            }
        }

        if (state.queryShape) {
            map.updateCurrentSelection(state.queryShape);
        }

        map.onPolygonUpdated = (shape) => {
            if (shape) {
                saveViewerState((state) => (state.queryShape = shape));
                updatePoints("onPolygonUpdated");
            } else {
                setActiveDrawing(null);
            }
        };

        map.onSelectedFilesChanged = (type, files) => {
            setSelectedFiles(files);
        };

        map.setBaseMap(state.baseMap);

        viewer.onLoadCountUpdated = (count) => {
            setLoadCount(count);
        };

        viewer.onCameraPositionChanged = ({ camera, offset }) => {
            map.updateCameraLocation(
                camera.position[0] + offset[0],
                camera.position[1] + offset[1],
                Math.atan2(camera.rotation[1], camera.rotation[0]),
                tableCRSRef.current,
            );
            saveViewerState(
                (state) =>
                    (state.camera = {
                        position: camera.position,
                        rotation: camera.rotation,
                        autoRotate: camera.autoRotate,
                        mode: camera.mode,
                    }),
            );
        };

        viewer.setColorMode(state.colorMode);

        window.addEventListener("keydown", (e) => {
            // do nothing if the user is typing in an input field
            if (e.target instanceof HTMLElement) {
                if (
                    e.target.tagName === "INPUT" ||
                    e.target.tagName === "TEXTAREA" ||
                    e.target.getAttribute("role") === "textbox" ||
                    e.target.isContentEditable
                ) {
                    return;
                }
            }

            // TODO: the shortcut keys should be visible somewhere in the UI
            if (e.key === "b") {
                setActiveDrawing("box");
            }

            if (e.key === "p") {
                setActiveDrawing("polygon");
            }

            // Map number keys (1..6) to corresponding color modes
            const asNumber = Number.parseInt(e.key);
            if (asNumber && (Object.values(COLOR_MODE).map((v) => v.key) as number[]).includes(asNumber)) {
                setColorMode(getColorModeById(asNumber));
            }

            if (e.key === "Escape") {
                setActiveDrawing(null);
            }
        });
    }, []);

    useEffect(() => {
        lumidb.setApiKey(apiKey);
        localStorage.setItem("apikey", apiKey);
    }, [apiKey]);

    useEffect(() => {
        if (mapElement.current) {
            map.setContainer(mapElement.current);
        }
    }, [mapElement]);

    useEffect(() => {
        const handle = setTimeout(async () => {
            setMetadataLoaded(null);
            tableNameRef.current = tableName;
            const pars = new URLSearchParams(window.location.search);
            if (tableName === null) {
                pars.delete("table");
            } else {
                pars.set("table", tableName);
            }
            window.history.replaceState({}, "", "?" + pars.toString());

            if (tableName === null) {
                setCurrentTableMetadata({ files: new Map() });
                setMetadataLoaded(null);
                return;
            }

            const { files, tableCRS } = await lumidb.getTableMetadata(tableName);
            tableCRSRef.current = tableCRS;
            setCurrentTableMetadata({ files: files });

            const years = new Set<number>();
            let biggest_index = 0;

            for (const [idx, m] of files.entries()) {
                if (typeof m.user_data.year === "number") {
                    years.add(m.user_data.year);
                }
                biggest_index = Math.max(biggest_index, idx);
            }

            map.updateVisibleBounds(files, tableCRS);
            viewer.setSensorPoses(files);

            const lookup: Array<[number, number, number]> = Array.from({ length: biggest_index });
            for (const [idx, m] of files.entries()) {
                lookup[idx] = getRampedColor(djb2Hash(getDate(m)));
            }
            CustomPointMaterial.updateDateColorLookup(lookup);

            setMetadataLoaded(tableName);
        }, 0);
        return () => clearTimeout(handle);
    }, [tableName]);

    useEffect(() => {
        const handle = updatePoints("metadataLoaded");
        return () => {
            clearTimeout(handle);
        };
    }, [metadataLoaded]);

    useEffect(() => {
        zRangeRef.current = zRange;
    }, [zRange]);

    useEffect(() => {
        zRangeSelectionRef.current = zRangeSelection;
    }, [zRangeSelection]);

    useEffect(() => {
        zRangeEnabledRef.current = zRangeEnabled;
    }, [zRangeEnabled]);

    useEffect(() => {
        zRangeRenderOnlyRef.current = zRangeRenderOnly;
        updatePoints("zRange render toggled");
    }, [zRangeRenderOnly]);

    useEffect(() => {
        saveViewerState((state) => (state.maxPoints = pointLimit));
        const timer = updatePoints("pointLimit");
        return () => clearTimeout(timer);
    }, [pointLimit]);

    useEffect(() => {
        saveViewerState((state) => (state.classificationMask = classificationMask));
        classMaskRef.current = classificationMask;
        viewer.setClassificationMask(classificationMask);
        const timer = updatePoints("classificationMask");
        return () => clearTimeout(timer);
    }, [classificationMask]);

    useEffect(() => {
        saveViewerState((state) => (state.colorMode = colorMode));
        viewer.setColorMode(colorMode);
        map.setMapColorMode(colorMode);
    }, [colorMode]);

    useEffect(() => {
        const selectedDates = selectableDates.filter(
            (_, i) => selectedTimeRange === null || (i >= selectedTimeRange[0] && i < selectedTimeRange[1]),
        );
        for (const d of lockedDates) {
            if (!selectedDates.includes(d)) {
                selectedDates.push(d);
            }
        }
        map.updateActiveDates(selectedDates);
        const timer = updatePoints("timeRange");
        return () => clearTimeout(timer);
    }, [selectedTimeRange, selectableDates, lockedDates]);

    useEffect(() => {
        saveViewerState((state) => (state.maxDensity = densityLimit));
        const timer = updatePoints("maxDensity");
        return () => clearTimeout(timer);
    }, [densityLimit]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.class = filterClassification));
    }, [filterClassification]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.date = filterTimeRange));
    }, [filterTimeRange]);

    useEffect(() => {
        saveViewerState((state) => (state.toggled.pointLimit = modifyPointLimit));
    }, [modifyPointLimit]);

    useEffect(() => {
        if (activeDrawing === "box") {
            map.drawBox();
        } else if (activeDrawing === "polygon") {
            map.drawPolygon();
        } else if (activeDrawing === null) {
            map.cancelDraw();
        }
    }, [activeDrawing]);

    useEffect(() => {
        const usedZRange = zRangeEnabled
            ? zRangeSelection.map((z) => z - (responseInfo?.bounds.min.z ?? 0))
            : [-Infinity, Infinity];
        DEFAULT_POINT_MATERIAL.setVisibleZRange(usedZRange);
    }, [zRangeSelection, responseInfo, zRangeEnabled]);

    useEffect(() => {
        viewer.setControlMode(cameraControlMode);
    }, [cameraControlMode]);

    function updateDensity(density: number) {
        if (density === MAX_DENSITY) {
            setDensityLimit(null);
        } else {
            setDensityLimit(density);
        }
    }

    function forgetApiKey() {
        localStorage.removeItem("apikey");
        setApiKey("");
    }

    function zRangeManuallyUpdated(newZRange: [number, number]): void {
        if (!zRangeRenderOnlyRef.current) {
            if (newZRange[0] === zRangeRef.current[0]) {
                newZRange[0] = -Infinity;
            }
            if (newZRange[1] === zRangeRef.current[1]) {
                newZRange[1] = Infinity;
            }
            setZRangeSelection(newZRange);

            updatePoints("zRange changed");
        } else {
            setZRangeSelection(newZRange);
        }
    }

    function toggleSelectedDateLock(date: string) {
        setLockedDates((prev) => {
            const newSelected = new Set(prev);
            if (newSelected.has(date)) {
                newSelected.delete(date);
            } else {
                newSelected.add(date);
            }
            return newSelected;
        });
    }

    return (
        <div className="flex h-full select-none">
            {!apiKey && (
                <ApiKeyInput
                    onSetKey={(newApiKey) => {
                        setApiKey(newApiKey);
                        setTableDialogOpen(true);
                    }}
                />
            )}
            {apiKey && tableDialogOpen && (
                <SelectTableDialog
                    lumidb={lumidb}
                    onTableSelected={(tableName) => {
                        setTableName(tableName);
                        setTableDialogOpen(false);
                    }}
                    onChangeAPIKey={() => forgetApiKey()}
                    onClose={() => setTableDialogOpen(false)}
                />
            )}
            <ResizablePanelGroup direction={uiLayout}>
                <ResizablePanel defaultSize={40}>
                    {/* MAP */}
                    <div id="map" ref={mapElement} className="relative h-full w-full">
                        <div className="pointer-events-none absolute left-2 top-2 z-30 flex w-[256px] flex-shrink-0 flex-col gap-2">
                            <div className="mx-2 my-4 w-44 opacity-80">
                                <img src={lumidbLogo} />
                            </div>

                            <div className="mb-4 flex flex-col items-start gap-1">
                                <div className="pointer-events-auto mb-4">
                                    <Button
                                        size="sm"
                                        variant={tableName ? "outline" : "destructive"}
                                        onClick={() => setTableDialogOpen(true)}
                                    >
                                        <Database className="h-4" />
                                        {tableName ?? "No Table Selected"}
                                    </Button>
                                </div>
                                <Button
                                    size="sm"
                                    variant={activeDrawing === "box" ? "default" : "outline"}
                                    onClick={() => {
                                        if (activeDrawing === "box") {
                                            setActiveDrawing(null);
                                        } else {
                                            setActiveDrawing("box");
                                        }
                                    }}
                                >
                                    <Square className="h-4" />
                                    Box
                                </Button>
                                <Button
                                    size="sm"
                                    variant={activeDrawing === "polygon" ? "default" : "outline"}
                                    onClick={() => {
                                        if (activeDrawing === "polygon") {
                                            setActiveDrawing(null);
                                        } else {
                                            setActiveDrawing("polygon");
                                        }
                                    }}
                                >
                                    <Diamond className="h-4" />
                                    Polygon
                                </Button>
                            </div>

                            <Collapsible
                                buttonClicked={() => setFilterTimeRange((v) => !v)}
                                icon={CalendarClock}
                                text="Date"
                                toggled={filterTimeRange}
                            >
                                <div className="mb-4 mt-4 flex w-fit flex-col gap-2">
                                    <div className="">
                                        <div className="flex select-none gap-0.5 px-2 text-sm">
                                            {selectableDates.map((date, i) => (
                                                <div
                                                    key={date}
                                                    onClick={() => {
                                                        setSelectedTimeRange(() => [i, i + 1]);
                                                    }}
                                                    className={
                                                        "w-5 rotate-180 cursor-pointer [writing-mode:vertical-lr] hover:text-gray-700 " +
                                                        (selectedTimeRange === null ||
                                                        lockedDates.has(date) ||
                                                        (i >= selectedTimeRange[0] && i < selectedTimeRange[1])
                                                            ? ""
                                                            : "text-gray-300")
                                                    }
                                                >
                                                    {date}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mt-1 flex select-none gap-0.5 px-2 text-sm">
                                            {selectableDates.map((date, i) => {
                                                const colorText =
                                                    "rgb(" + getRampedColor(djb2Hash(date)).join(", ") + ")";
                                                const inRange =
                                                    selectedTimeRange === null ||
                                                    lockedDates.has(date) ||
                                                    (i >= selectedTimeRange[0] && i < selectedTimeRange[1]);
                                                return (
                                                    <div
                                                        key={date}
                                                        onClick={() => {
                                                            toggleSelectedDateLock(date);
                                                        }}
                                                        style={{
                                                            backgroundColor: inRange
                                                                ? colorText
                                                                : "rgb(100,100,100,0.2)",
                                                        }}
                                                        className={
                                                            "flex h-5 w-5 cursor-pointer rounded-sm " +
                                                            (inRange ? "border border-black" : "")
                                                        }
                                                    >
                                                        {lockedDates.has(date) && (
                                                            <Lock className="m-px h-4 w-4"></Lock>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <Slider
                                            className="mt-1.5"
                                            min={0}
                                            max={selectableDates.length}
                                            value={selectedTimeRange ?? [0, selectableDates.length]}
                                            minStepsBetweenThumbs={1}
                                            onValueChange={(value) => {
                                                setSelectedTimeRange(value as [number, number]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </Collapsible>

                            <Collapsible
                                toggled={modifyPointLimit}
                                icon={SlidersHorizontal}
                                text="Point Limit"
                                buttonClicked={() => setModifyPointLimit((v) => !v)}
                            >
                                <div className="w-40">
                                    <div className="flex justify-between">
                                        <div>Point Count:</div> <div>{(pointLimit / 1_000_000).toFixed(3)} M</div>
                                    </div>
                                    <Slider
                                        className="w-full py-2"
                                        min={10_000}
                                        max={8_000_000}
                                        step={10_000}
                                        value={[pointLimit]}
                                        onValueChange={(value) => {
                                            setPointLimit(value[0]);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="flex justify-between">
                                        <div>Max Density:</div>
                                        <div>{densityLimit?.toFixed(1) ?? "∞"} pt/m² </div>
                                    </div>
                                    <Slider
                                        className="w-full py-2"
                                        min={0.1}
                                        max={MAX_DENSITY}
                                        step={0.1}
                                        value={[densityLimit ?? MAX_DENSITY]}
                                        onValueChange={(value) => {
                                            updateDensity(value[0]);
                                        }}
                                    />
                                </div>
                            </Collapsible>

                            <Collapsible
                                text="Class"
                                icon={Filter}
                                toggled={filterClassification}
                                buttonClicked={() => setFilterClassification((v) => !v)}
                                isWarning={() => classificationMask !== 0xffff_ffff}
                                extra={
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        onClick={() => {
                                            if (classificationMask === 0xffff_ffff) {
                                                setClassificationMask(0);
                                            } else {
                                                setClassificationMask(0xffff_ffff);
                                            }
                                        }}
                                    >
                                        Toggle All
                                    </Button>
                                }
                            >
                                <div className="flex flex-col gap-2">
                                    {/* TODO: should only show the classes that are actually found in the dataset */}
                                    {(!responseInfo || responseInfo.seenClassifications.size === 0) && (
                                        <div className="text-grey-800">No points loaded.</div>
                                    )}
                                    {Array.from(responseInfo?.seenClassifications ?? [])
                                        .filter((n) => n < 32)
                                        .toSorted((a, b) => a - b)
                                        .map((k) => {
                                            const visible = (classificationMask & (1 << Number(k))) > 0;
                                            const color =
                                                "rgb(" +
                                                getClassificationColor(Number(k))
                                                    .map((n) => n * 255)
                                                    .join(", ") +
                                                ")";
                                            return (
                                                <div className="flex items-center gap-1" key={k}>
                                                    <Checkbox
                                                        id={"toggle-class-" + k}
                                                        checked={visible}
                                                        className="hidden"
                                                        onCheckedChange={(checked) => {
                                                            setClassificationMask((prev) => {
                                                                // use >>> 0 to force the numbers to be unsigned
                                                                const next = checked
                                                                    ? (prev | (1 << Number(k))) >>> 0
                                                                    : (prev & ~(1 << Number(k))) >>> 0;
                                                                return next;
                                                            });
                                                        }}
                                                    />

                                                    <Label
                                                        htmlFor={"toggle-class-" + k}
                                                        className="flex cursor-pointer items-center gap-1 text-xs"
                                                    >
                                                        {/* TODO: the checkbox and color visualization could be combined */}
                                                        <div
                                                            key={k}
                                                            style={{
                                                                backgroundColor: visible ? color : "rgb(100,100,100,0)",
                                                            }}
                                                            className={
                                                                "flex h-4 w-4 rounded-sm border " +
                                                                (visible ? "border-black" : "border-gray-400")
                                                            }
                                                        ></div>
                                                        <div className={visible ? "" : "line-through"}>
                                                            {k}: {lasClasses[k] ?? "Unknown"}
                                                        </div>
                                                    </Label>
                                                </div>
                                            );
                                        })}
                                </div>
                            </Collapsible>
                        </div>

                        <div className="absolute left-2 top-2 z-10 flex items-start gap-1"></div>

                        <div className="absolute right-2 top-2 z-10 flex gap-1 text-sm">
                            <Button variant="outline" size="sm" onClick={() => map.zoom(1)}>
                                <Plus className="h-4" />
                            </Button>

                            <Button variant="outline" size="sm" onClick={() => map.zoom(-1)}>
                                <Minus className="h-4" />
                            </Button>

                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" size="sm" title="Change basemap">
                                        <MapIcon className="h-4" /> Map
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("osm")}>
                                        OpenStreetMap
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("googleRoads")}>
                                        Google
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("kapsiOrto")}>
                                        MML Ortho
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => map.setBaseMap("kapsiTausta")}>
                                        MML Basemap
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>

                        <div className="absolute bottom-2 left-2 z-10 rounded-md bg-white/40 p-2 text-xs backdrop-blur-sm">
                            {selectedFiles.map((f) => (
                                // TODO: The SourceFileMetadata should contain a proper unique key for each item, and it should be used here
                                <div
                                    className="items-top flex gap-1"
                                    key={f.filename + "-" + f.num_points + "-" + f.file_size}
                                >
                                    <div>
                                        <FileBox className="h-5" />
                                    </div>
                                    <div>{f.filename}</div>
                                    <div>{humanSize(f.file_size)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ResizablePanel>
                <ResizableHandle withHandle></ResizableHandle>
                <ResizablePanel defaultSize={60}>
                    {/* VIEWER */}
                    <div id="viewer" className="relative h-full w-full">
                        <div className="absolute left-2 top-2">
                            <div className="flex items-center gap-1"></div>
                        </div>

                        <div className="absolute left-2 top-2 flex gap-1">
                            {loadCount > 0 && (
                                <Button size="sm" variant="outline">
                                    <LoaderCircle className="animate-spin"></LoaderCircle>
                                    <div className="ml-2">Loading</div>
                                </Button>
                            )}
                        </div>

                        <div className="absolute right-2 top-2 flex gap-1">
                            <DropdownMenu modal={false}>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" size="sm" title="Change color mode">
                                        <Paintbrush className="h-4" />
                                        Color: {COLOR_MODE[colorMode].name}
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="ml-2 w-56">
                                    <DropdownMenuGroup>
                                        {Object.entries(COLOR_MODE).map(([modeName, modeVals]) => (
                                            <DropdownMenuItem
                                                key={modeName}
                                                onClick={() => setColorMode(modeName as ColorMode)}
                                            >
                                                {modeVals.name}
                                                <DropdownMenuShortcut>{modeVals.key}</DropdownMenuShortcut>
                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <Button
                                size="sm"
                                className="ml-2"
                                variant="outline"
                                onClick={() => viewer.adjustPointSize(-1)}
                                title="Decrease point size"
                            >
                                <Dot className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.adjustPointSize(1)}
                                title="Increase point size"
                            >
                                <Circle className="h-4" />
                            </Button>

                            <Button
                                size="sm"
                                className="mx-2"
                                variant={zRangeEnabled ? "default" : "outline"}
                                onClick={() => setZRangeEnabled(!zRangeEnabled)}
                                title="Limit Z"
                            >
                                <AlignVerticalSpaceAround className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => setCameraControlMode(cameraControlMode === "map" ? "fps" : "map")}
                                title="Toggle camera mode"
                            >
                                {cameraControlMode === "fps" ? (
                                    <Camera className="h-4" />
                                ) : (
                                    <Rotate3D className="h-4" />
                                )}
                            </Button>

                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.resetCamera()}
                                title="Reset camera"
                            >
                                <SwitchCamera className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => viewer.toggleAnimation()}
                                title="Toggle animation"
                            >
                                <Play className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => resetViewerState()}
                                title="Reset everything"
                                className="mx-2"
                            >
                                <ListRestart className="h-4" />
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={() => forgetApiKey()}
                                title="Forget API Key"
                                className="ml-2"
                            >
                                <LogOut className="pointer-events-auto h-4 cursor-pointer" />
                            </Button>
                        </div>

                        {zRangeEnabled && (
                            <div className="absolute right-2 top-12 flex flex-col gap-2 rounded-md bg-white/40 p-2 text-left backdrop-blur-sm">
                                <div className="flex justify-between">
                                    <div className="font-bold">Z Range</div>
                                    <div className="flex items-center gap-1">
                                        <Label htmlFor="z-range-render-only">Render-only</Label>
                                        <Checkbox
                                            id="z-range-render-only"
                                            checked={zRangeRenderOnly}
                                            onCheckedChange={(v) => setZRangeRenderOnly(v === true)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Slider
                                        className="w-72"
                                        min={zRange[0]}
                                        max={zRange[1]}
                                        step={0.1}
                                        value={zRangeSelection}
                                        onValueChange={(value) => zRangeManuallyUpdated(value as [number, number])}
                                    />
                                </div>
                                <div className="flex w-full justify-between">
                                    <div>{zRangeSelection[0].toFixed(2)}</div>
                                    <div>{zRangeSelection[1].toFixed(2)}</div>
                                </div>
                            </div>
                        )}

                        <div className="absolute bottom-2 right-2 rounded-md bg-white/40 p-2 text-sm backdrop-blur-sm">
                            {responseInfo && (
                                <div className="flex flex-col items-stretch">
                                    <div className="font-bold text-gray-500">Response</div>

                                    <Info heading="Time">{humanDuration(responseInfo.elapsed)}</Info>
                                    <Info heading="Points">{(responseInfo.pointCount / 1_000_000).toFixed(3)} M</Info>
                                    <Info heading="Size">{humanSize(responseInfo.byteSize)}</Info>
                                    <Info heading="Area">{humanArea(getQueryShapeArea(responseInfo.queryShape))}</Info>
                                    <Info heading="Density">
                                        {(responseInfo.pointCount / responseInfo.queryArea).toFixed(2)} pt/m²
                                    </Info>

                                    <div className="mt-2 flex flex-col gap-2">
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            className="w-full"
                                            onClick={() => onShareLink()}
                                        >
                                            <Share2 className="h-4" />
                                            {shareLinkText}
                                        </Button>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button size="sm" variant="outline" className="w-full">
                                                    <Download className="h-4" />
                                                    Export
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <DropdownMenuItem
                                                    onClick={() => {
                                                        if (viewer && tableName && state.queryShape)
                                                            PointChunk.startFileDownload({
                                                                lumidb,
                                                                tableName,
                                                                classificationMask,
                                                                sourceFileFilter,
                                                                queryShape: addZRangeToQueryShape(
                                                                    state.queryShape,
                                                                    zRangeEnabled && !zRangeRenderOnly
                                                                        ? zRangeSelection
                                                                        : null,
                                                                ),
                                                                maxPoints: state.maxPoints,
                                                                maxDensity: state.maxDensity,
                                                            });
                                                    }}
                                                >
                                                    LAZ
                                                </DropdownMenuItem>
                                                <DropdownMenuItem disabled>LAS</DropdownMenuItem>
                                                <DropdownMenuItem disabled>e57</DropdownMenuItem>
                                                <DropdownMenuItem disabled>glTF</DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            )}
                        </div>

                        {responseInfo && responseInfo.seenIndices.size > 0 && (
                            <div className="absolute bottom-2 left-2 z-10 flex max-h-[calc(100%_-_1rem)] flex-col gap-1 overflow-y-auto rounded-sm bg-white/40 p-2 text-xs backdrop-blur-sm">
                                <div
                                    className="flex w-full cursor-pointer items-center justify-between gap-1 text-sm font-bold text-gray-500"
                                    onClick={() => {
                                        setFilesUsedCollapsed((old) => !old);
                                    }}
                                >
                                    <div>Files used: {responseInfo.seenIndices.size}</div>

                                    {filesUsedCollapsed ? (
                                        <ChevronUp className="h-5"></ChevronUp>
                                    ) : (
                                        <ChevronDown className="h-5"></ChevronDown>
                                    )}
                                </div>

                                {!filesUsedCollapsed && (
                                    <FilesByLicense
                                        fileMetadata={currentTableMetadata.files}
                                        responseInfo={responseInfo}
                                        viewer={viewer}
                                    />
                                )}
                            </div>
                        )}

                        <ViewerContainer />
                    </div>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
}
