
#define PI 3.141592653589793

uniform float pointSize;
uniform int colorMode;
uniform int classificationMask;
uniform vec3 colorRamp[256];
uniform int colorRampLength;
uniform sampler2D indexToColorLookup;
uniform float intensityRange[2];
uniform float zRange[2];
uniform uint filteredPoseId[256];
uniform uint filteredPoseIdLength;

in vec3 color;
// TODO: how to make classification come in as an int?
in float classification;
in int source_file_id;
in float intensity;
in float point_source_id;

out vec3 vColor;
flat out int vDiscard;

const int TEXTURE_SIZE = 256;

vec3 getDateColor() {
    int x = source_file_id % TEXTURE_SIZE;
    int y = source_file_id / TEXTURE_SIZE;
	vec2 uv = vec2(
        (float(x) + 0.5) / float(TEXTURE_SIZE),
        (float(y) + 0.5) / float(TEXTURE_SIZE)
    );
    return texture2D(indexToColorLookup, uv).rgb;
}

vec3 classColor(float classification) {
	int c = int(classification);

	if (c == 0) {
		// never classified
		return vec3(0.3, 0.3, 0.3);
	} else if (c == 1) {
		// unclassified
		return vec3(0.8, 0.8, 0.8);
	} else if (c == 2) {
		// ground
		return vec3(0.5, 0.35, 0.15);
	} else if (c == 3) {
		// low vegetation
		return vec3(0.4, 0.8, 0.4);
	} else if (c == 4) {
		// medium vegetation
		return vec3(0.0, 1.0, 0.0);
	} else if (c == 5) {
		// high vegetation
		return vec3(0.0, 0.6, 0.0);
	} else if (c == 6) {
		// building
		return vec3(0.9, 0.9, 0.0);
	} else if (c == 7) {
		// noise
		return vec3(1.0, 0.3, 0.70);
	} else if (c == 9) {
		// water
		return vec3(0.3, 0.3, 0.9);
	} else {
		// fallback / default
		float O = 0.25;
		float x = classification / 16.0;
		float r = 0.5 + 0.5 * cos(2.0 * PI * (2.0 * x + 0.00 + O));
		float g = 0.5 + 0.5 * cos(2.0 * PI * (1.0 * x + 0.66 + O));
		float b = 0.5 + 0.5 * cos(2.0 * PI * (1.0 * x + 0.33 + O));
		return vec3(r, g, b);
	}
}

void main() {
	mat4 modelToScreen = projectionMatrix * modelViewMatrix;

	vec4 screenPosition = modelToScreen * vec4(position, 1.0);

	gl_PointSize = pointSize;
	gl_Position = screenPosition;
	vDiscard = 0;

	int c = 1 << int(classification);
	if ((c & classificationMask) == 0) {
		vColor = vec3(1.0, 1.0, 1.0);
		vDiscard = 1;
	}

	if (filteredPoseIdLength > uint(0)) {
		bool keep = false;
		for (int i = 0; uint(i) < filteredPoseIdLength; i++) {
			if (point_source_id == float(filteredPoseId[i])) {
				keep = true;
			}
		}
		if (!keep) {
			vDiscard = 1;
			return;
		}
	}

	if (position.z < zRange[0] || position.z > zRange[1]) {
		// vColor = vec3(0.8, 0.9, 1.0);
		vDiscard = 1;
	}
	else if (colorMode == 1) {
		// rgb
		vColor = color;
	} else if (colorMode == 2) {
		// classification
		vColor = classColor(classification);
	} else if (colorMode == 3) {
		// intensity
		float gamma = 0.3;
		float scaledIntensity = (intensity - intensityRange[0]) / (intensityRange[1] - intensityRange[0]);
		vColor = vec3(pow(scaledIntensity, gamma));
	} else if (colorMode == 4) {
		// point source id
		vec3 sourceColor = colorRamp[int(point_source_id) % colorRampLength];
		vColor = mix(sourceColor, color, 0.33);
	} else if (colorMode == 5) {
		// source file id
		vec3 metaColor = colorRamp[source_file_id % colorRampLength];
		vColor = mix(metaColor, color, 0.33);
	} else if (colorMode == 6) {
		// date
		vColor = mix(getDateColor(), color, 0.33);
	} else {
		vColor = vec3(1.0, 0.0, 0.0);
	}
}
