import { LumiDB } from "@lumidb/lumidb";
import { BufferGeometry, Points, Vector3 } from "three";
import { CustomPointMaterial } from "./point-material";

export const DEFAULT_POINT_MATERIAL = new CustomPointMaterial();

/// Represents an area selection made on the map. The aabb field is a bounding box in the form [minX, minY, maxX, maxY]
// (same ordering as an Extent in OpenLayers).
export type QueryShape = { polygon: number[][][] } | { aabb: number[] };

/// Represents an 2D selection with an (optional) attached Z range.
export type QueryShapeWithZRange =
    | { polygon: number[][][]; zRange: [number | null, number | null] | null }
    | { aabb: number[]; zRange: [number, number] | null };

export function addZRangeToQueryShape(shape: QueryShape, zRange: [number, number] | null) {
    if ("polygon" in shape) {
        return { polygon: shape.polygon, zRange };
    } else {
        return { aabb: shape.aabb, zRange };
    }
}

function queryShapeToApiShape(shape: QueryShapeWithZRange) {
    if ("polygon" in shape) {
        return {
            Polygon: [shape.polygon, shape.zRange] satisfies [number[][][], [number | null, number | null] | null],
        };
    } else {
        const zMin = shape.zRange == null || shape.zRange[0] === -Infinity ? -10_000 : shape.zRange[0];
        const zMax = shape.zRange == null || shape.zRange[1] === Infinity ? 10_000 : shape.zRange[1];
        return {
            Aabb: {
                min: [shape.aabb[0], shape.aabb[1], zMin] satisfies [number, number, number],
                max: [shape.aabb[2], shape.aabb[3], zMax] satisfies [number, number, number],
            },
        };
    }
}

type PointQueryParams = {
    lumidb: LumiDB;
    tableName: string;
    queryShape: QueryShapeWithZRange;
    maxPoints: number | null;
    maxDensity: number | null;
    sourceFileFilter: number[] | null;
    classificationMask: number | null;
    abortSignal?: AbortSignal;
};

// TODO: is this class even needed? Seems like it just wraps stuff from the API response. Perhaps we could
// just use the api response object directly.
export class PointChunk {
    points: Points;
    pointCount: number;
    byteSize: number;
    seenIndices: Set<number>;
    seenClassifications: Set<number>;
    intensityRange: [number, number];
    offset: Vector3;
    queryArea: number;
    bounds: { min: number[]; max: number[] };

    constructor(
        points: Points,
        pointCount: number,
        byteSize: number,
        seenIndices: Set<number>,
        classifications: Set<number>,
        intensityRange: [number, number],
        offset: Vector3,
        queryArea: number,
        bounds: { min: number[]; max: number[] },
    ) {
        this.points = points;
        this.pointCount = pointCount;
        this.byteSize = byteSize;
        this.seenIndices = seenIndices;
        this.offset = offset;
        this.queryArea = queryArea;
        this.intensityRange = intensityRange;
        this.seenClassifications = classifications;
        this.bounds = bounds;
    }

    static async startFileDownload({
        lumidb,
        tableName,
        maxPoints,
        maxDensity,
        sourceFileFilter,
        queryShape,
        classificationMask,
    }: PointQueryParams) {
        const classFilterArray = [];
        for (let i = 0; i < 32; i++) {
            if (classificationMask === null || classificationMask & (1 << i)) {
                classFilterArray.push(i);
            }
        }
        const resp = await lumidb.query({
            tableName,
            maxPoints,
            maxDensity,
            sourceFileFilter,
            queryBoundary: queryShapeToApiShape(queryShape),
            queryCRS: "EPSG:3857",
            classFilter: classFilterArray,
            outputType: "laz",
        });

        // Currently the query_spatial uses POST, so the download can't be started with a simple link
        // TODO: investigate if this starting the download this way has some performance impact
        const blob = await resp.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = resp.headers.get("Content-Disposition")?.split("filename=")[1].replaceAll('"', "") || "export.laz";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    static async loadFromAPI({
        lumidb,
        tableName,
        maxPoints,
        maxDensity,
        sourceFileFilter,
        queryShape,
        classificationMask,
        abortSignal,
    }: PointQueryParams) {
        const start = performance.now();
        const classFilterArray = [];
        for (let i = 0; i < 32; i++) {
            if (classificationMask === null || classificationMask & (1 << i)) {
                classFilterArray.push(i);
            }
        }
        const resp = await lumidb.query({
            tableName,
            maxPoints,
            maxDensity,
            sourceFileFilter,
            queryBoundary: queryShapeToApiShape(queryShape),
            queryCRS: "EPSG:3857",
            classFilter: classFilterArray,
            outputType: "threejs",
            abortSignal,
        });

        const total_time = Math.ceil(performance.now() - start);

        console.log("fetch complete", {
            total_time: total_time,
            processing_time: resp.processingTimeMs,
            transfer_time: total_time - resp.processingTimeMs,
            point_count: resp.pointCount,
            offset: resp.offset,
        });

        const points = new Points(resp.pointGeometry as unknown as BufferGeometry, DEFAULT_POINT_MATERIAL);

        return new PointChunk(
            points,
            resp.pointCount,
            resp.bytes,
            new Set(resp.sourceFileIds),
            new Set(resp.classifications),
            resp.intensityRange,
            new Vector3().fromArray(resp.offset),
            resp.queryArea,
            resp.bounds,
        );
    }
}
