import { SourceFileMetadata } from "@lumidb/lumidb";

export function humanSize(bytes: number) {
    if (bytes > 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    if (bytes > 1024) return (bytes / 1024).toFixed(2) + " kB";
    return bytes + " B";
}

export function humanArea(sqMeters: number) {
    if (sqMeters > 1_000_000) return (sqMeters / 1_000_000).toFixed(1) + " km²";
    if (sqMeters > 10_000) return (sqMeters / 10_000).toFixed(1) + " ha";
    return sqMeters.toFixed(1) + " m²";
}

export function humanDuration(durationMs: number) {
    if (durationMs > 1_000) return (durationMs / 1_000).toFixed(2) + " s";
    return durationMs.toFixed(2) + " ms";
}

export const COLOR_RAMP: Array<[number, number, number]> = [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
    [177, 89, 40],
];

// COLOR_RAMP.sort(() => Math.random() - 0.5);

export function getRampedColor(idx: number) {
    return COLOR_RAMP[idx % COLOR_RAMP.length];
}

// TODO: This a duplicate definition of the function that is defined in point.vert GLSL code. The color
// ramp should be built in JS and passed to the shader as a uniform.
export function getClassificationColor(classification: number) {
    switch (classification) {
        case 0: // never classified
            return [0.3, 0.3, 0.3];
        case 1: // unclassified
            return [0.8, 0.8, 0.8];
        case 2: // ground
            return [0.5, 0.35, 0.15];
        case 3: // low vegetation
            return [0.4, 0.8, 0.4];
        case 4: // medium vegetation
            return [0.0, 1.0, 0.0];
        case 5: // high vegetation
            return [0.0, 0.6, 0.0];
        case 6: // building
            return [0.9, 0.9, 0.0];
        case 7: // noise
            return [1.0, 0.3, 0.7];
        case 9: // water
            return [0.3, 0.3, 0.9];
        default: {
            // fallback / default
            const O = 0.25;
            const x = classification / 16.0;
            const r = 0.5 + 0.5 * Math.cos(2.0 * Math.PI * (2.0 * x + 0.0 + O));
            const g = 0.5 + 0.5 * Math.cos(2.0 * Math.PI * (1.0 * x + 0.66 + O));
            const b = 0.5 + 0.5 * Math.cos(2.0 * Math.PI * (1.0 * x + 0.33 + O));
            return [r, g, b];
        }
    }
}

export function djb2Hash(s: string): number {
    let hash = 5381;
    for (let i = 0; i < s.length; i++) {
        hash = (hash << 5) + hash + s.charCodeAt(i);
    }
    return hash >>> 0;
}

export function getDate(meta: SourceFileMetadata): string {
    return typeof meta.user_data.date === "string"
        ? meta.user_data.date
        : typeof meta.user_data.year === "number"
          ? meta.user_data.year.toString()
          : "n/a";
}
